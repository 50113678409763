<template v-if="data">
  <div>
    <Text
      v-if="data.schemaName == 'text'"
      :data="data"
      :perexLength="perexLength"
      v-on:remove="passRemove"
      :group="group"
    />

    <Image v-if="data.schemaName == 'image'" :data="data" />

    <template v-if="data.schemaName == 'gallery'">
      <Image v-if="data.type == 'carousel'" :data="data" />
      <Gallery v-else :data="data" />
    </template>

    <Product v-else-if="data.schemaName == 'product'" :data="data" />

    <Contact v-else-if="data.schemaName == 'contact'" :data="data" />

    <template v-else-if="data.schemaName == 'group'">
      <div v-if="data.type == 'news' && $editor" class="text-center">
        <div class="btn btn-sm btn-success mt-3 mb-0" @click="addNews">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-plus-lg"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
            />
          </svg>
        </div>
      </div>
      <Boxes v-if="data.type == 'boxes'" :data="data" />
      <draggable
        class="row row--dense"
        animation="250"
        draggable=".draggable"
        v-model="list"
        item-key="id"
        :forceFallback="true"
        v-else-if="$editor && list"
        @sort="sorted"
      >
        <template #item="{ element, index }">
          <div class="draggable">
            <General
              :data="data.content[index]"
              v-bind:key="index"
              :perexLength="data.perexLength"
              :group="data.type == 'news'"
              :x="element"
            />
          </div>
        </template>
      </draggable>
      <General
        v-else
        v-for="(item, j) in data.content"
        v-bind:key="j"
        :data="item"
        :perexLength="data.perexLength"
        v-on:remove="removeContent"
        :group="data.type == 'news'"
      />
    </template>
  </div>
</template>

<script>
import General from "./General.vue";
import Text from "../Text/Text.vue";
import Image from "../Image/Image.vue";
import Gallery from "../Gallery/Gallery.vue";
import Contact from "../Contact/Contact.vue";
import Product from "../Product/Product.vue";
import Boxes from "../Boxes/Boxes.vue";
import draggable from "vuedraggable";

import { createText, updateContent } from "@/api/api.js";

import "./General.scss";

export default {
  name: "General",
  components: {
    Text,
    Image,
    Gallery,
    General,
    Product,
    Contact,
    Boxes,
    draggable,
  },
  data() {
    return {
      list: [],
    };
  },
  props: {
    data: Object,
    perexLength: Number,
    group: { type: Boolean, default: false },
  },
  created() {
    if (this.$editor && this.data.schemaName == "group") {
      this.list = this.data.contentList.iv;
    }
  },
  mounted() {
    // console.log(this.data);
  },
  methods: {
    async addNews() {
      const response = await createText();
      // console.log(response);
      // console.log(response.id);
      if (response.id) {
        let contentList = this.data.contentList;
        contentList.iv.push(response.id);
        // console.log(contentList);
        await updateContent("group", this.data.cmsid, {
          content: contentList,
        });
        this.$emit("reload");
      }
    },
    passRemove(id) {
      this.$emit("remove", id);
    },
    async removeContent(id) {
      let contentList = this.data.contentList;
      // console.log(this.data)
      const index = contentList.iv.indexOf(id);
      if (index > -1) {
        // console.log(index);
        if (confirm("Potrďte smazání obsahu.")) {
          contentList.iv.splice(index, 1);
          // console.log(contentList)
          if (
            await updateContent("group", this.data.cmsid, {
              content: contentList,
            })
          ) {
            this.$emit("reload");
          }
        }
      }
    },
    async sorted() {
      const data = { content: { iv: this.list } };
      if (await updateContent("group", this.data.cmsid, data)) {
        this.$emit("reload");
      }
    },
    log(msg) {
      console.log(msg);
    },
  },
};
</script>

<style lang="scss">
div.draggable {
  cursor: n-resize;
  margin-top: 2px;
  margin-bottom: 2px;
}
div.draggable:hover {
  margin-top: 0px;
  border-top: 2px gray dashed;
  margin-bottom: 0px;
  border-bottom: 2px gray dashed;
}
</style>
