<template v-if="data">
  <div
    class="product mt-5"
    v-if="
      (!this.$route.query.kategorie &&
        !this.$route.query.category &&
        !this.$route.query.categorie) ||
      (data.category &&
        (data.category.includes(this.$route.query.kategorie) ||
          data.category.includes(this.$route.query.category) ||
          data.category.includes(this.$route.query.categorie)))
    "
  >
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body"><b>Obsah uložen!</b></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-12">
          <div class="row">
            <template v-for="(item, i, j) in data.image">
              <picture v-if="i == 0" class="col-xl-12 main" v-bind:key="i">
                <img
                  @click="show(i)"
                  :src="$assetsUrl + item + '?width=380&quality=85'"
                  :alt="data.title"
                />
              </picture>
              <picture v-else class="col-4 others" v-bind:key="j">
                <img
                  @click="show(i)"
                  :src="
                    $assetsUrl +
                    item +
                    '?width=120&height=90&mode=Crop&quality=85'
                  "
                  :alt="data.title"
                />
              </picture>
            </template>
          </div>
        </div>
        <div class="col-lg-8 col-md-7 col-12">
          <h2 class="title" v-if="$editor">
            <InlineEditor
              class="description"
              @input="save"
              v-model="form.title"
            />
          </h2>

          <h2 class="title" v-else-if="data.title">{{ data.title }}</h2>
          <p class="description" v-if="$editor">
            <InlineEditor v-model="form.text" @input="save" />
          </p>
          <p class="description" v-else v-html="data.text"></p>

          <draggable
            class="row row--dense"
            animation="250"
            draggable=".draggable"
            v-model="form.parameters"
            item-key="id"
            v-if="$editor"
            @sort="save"
          >
            <template #item="{ element, index }">
              <div class="draggable">
                <div
                  class="btn btn-sm btn-danger mt-1 me-2"
                  @click="removeParameter(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </div>
                <span>
                  <InlineEditor v-model="element.name" @input="save" class="ib"
                /></span>
                <span class="value">
                  <InlineEditor
                    v-model="element.value"
                    @input="save"
                    class="ib"
                /></span>
              </div>
            </template>
          </draggable>
          <ul v-else>
            <li v-for="(item, i) in data.parameters" v-bind:key="i">
              <span v-html="item.name" class=""></span>
              <span v-html="item.value" class="value"></span>
            </li>
          </ul>
          <div
            v-if="$editor"
            class="btn btn-sm btn-success"
            @click="addParameter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
              />
            </svg>
          </div>
          <div v-if="data.price">{{ data.price }}</div>

          <a
            :href="$assetsUrl + data.doc + '/' + assets[data.doc].slug"
            class="float-end"
            target="_blank"
            v-if="data.doc != '' && assets[data.doc]"
          >
            <img
              alt="PDF"
              src="../../assets/img/pdf.svg"
              class="pdf-download"
            />
          </a>
        </div>
      </div>
    </div>

    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    />
  </div>
</template>

<script>
import "./Product.scss";
import { truncate } from "../../helpers/truncate";
import InlineEditor from "../InlineEditor.vue";
import draggable from "vuedraggable";
import { getAssetsInfo, updateContent } from "../../api/api.js";

export default {
  name: "Product",
  components: {
    InlineEditor,
    draggable,
  },
  data() {
    return {
      form: null,
      visible: false,
      index: 0,
      assets: {},
      savedNotification: false,
      imgs: this.data.image.map(
        (x) => this.$assetsUrl + x + "?width=1920&quality=85"
      ),
    };
  },
  props: {
    data: Object,
    perexLength: Number,
  },
  async created() {
    if (this.$editor) {
      this.form = { ...this.data };
    }
    if (this.data.doc) {
      const id = this.data.doc[0];
      const info = await getAssetsInfo(id);
      this.assets[id] = info.items[0];
    }
  },
  methods: {
    truncate,
    getAssetsInfo(ids) {
      console.log(getAssetsInfo(ids));
    },
    async getAssetSlug(ids) {
      const info = await getAssetsInfo(ids);
      console.log(info.items[0].slug);
      return await info.items[0].slug;
    },
    log(msg) {
      console.log(msg);
    },
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    save() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1500);
    },
    addParameter() {
      this.form.parameters.push({ name: "název", value: "hodnota" });
    },
    removeParameter(i) {
      this.form.parameters.splice(i, 1);
      this.saveAction();
    },
    async saveAction() {
      console.log(this.data.cmsid);
      if (
        // TO-DO:
        await updateContent("product", this.data.cmsid, {
          title: { [this.$lang]: this.form.title },
          text: { [this.$lang]: this.form.text },
          parameters: { [this.$lang]: this.form.parameters },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
};
</script>

<style></style>
