<template v-if="data">
  <div class="contact">
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body">Uloženo</div>
        </div>
      </div>
    </div>
    <div class="container mt-5" v-if="$editor">
      <InlineEditor @input="save" v-model="form.mainContact" />
      <div v-for="(item, i) in form.contacts" v-bind:key="i" class="mt-3">
        <div>
          <b><InlineEditor v-model="item.name" /></b>
        </div>

        <InlineEditor @input="save" v-model="item.responsibility" />
        <InlineEditor @input="save" v-model="item.phone" />
        <InlineEditor @input="save" v-model="item.email" />
        <InlineEditor @input="save" v-model="item.ico" />
      </div>
      <div>
      <a href="https://www.facebook.com/stanovakprivesy/" target="_blank"
        ><img src="../../assets/img/facebook.png" width="50" class="mt-3"
      /></a>
    </div>
      <hr class="contact" />

      <div class="row">
      <div class="col-6">
      <div>
        <span class="contact2"><InlineEditor @input="save" v-model="form.contact2Title" /></span>
      </div>

      <InlineEditor @input="save" v-model="form.contact2" />
    </div>
      <div class="col-6 text-end">
      <span class="contact2"><InlineEditor v-model="form.downloadTitle" /></span>
      <div v-if="Object.keys(assets).length >= data.download.length">
        <a
          :href="$assetsUrl + file + '/' + assets[file].slug"
          class="float-end"
          target="_blank"
          v-for="(file, i) in data.download"
          v-bind:key="i"
        >
          <div class="download">
            <img
            alt="PDF"
            src="../../assets/img/pdf.svg"
            class="pdf-download"
          />
          <div class="text-center">{{ assets[file].metadata.Desc }}</div>
        </div>
        </a>
      </div>
      </div>
    </div>
    </div>
    <div class="container mt-5" v-else>
      <div v-html="data.mainContact"></div>
      <div v-for="(item, i) in data.contacts" v-bind:key="i" class="mt-3">
        <div>
          <b>{{ item.name }}</b>
        </div>
        <div v-html="item.responsibility"></div>
        <div v-html="item.phone"></div>
        <div v-html="item.email"></div>
        <div v-html="item.ico"></div>
      </div>
      <a href="https://www.facebook.com/stanovakprivesy/" target="_blank"
        ><img src="../../assets/img/facebook.png" width="50" class="mt-3"
      /></a>
      <hr class="contact" />
      <div class="row">
      <div class="col-6">
      <span class="contact2" v-html="data.contact2Title"></span>
      <div v-html="data.contact2"></div>
    </div>
    <div class="col-6 text-end">
    <span class="contact2" v-html="data.downloadTitle"></span>
    <div v-if="Object.keys(assets).length >= data.download.length">
      <a
        :href="$assetsUrl + file + '/' + assets[file].slug"
        class="float-end"
        target="_blank"
        v-for="(file, i) in data.download"
        v-bind:key="i"
      >
        <div class="download">
          <img
          alt="PDF"
          src="../../assets/img/pdf.svg"
          class="pdf-download"
        />
        <div class="text-center">{{ assets[file].metadata.Desc }}</div>
      </div>
      </a>
    </div>
    </div>
    </div>
  </div>
  <iframe
    class="map"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB2rm_wz6hNHvyNxupuRFs_1hApu2FEud4
    &q=Stanovák+Machines+s.+r.+o./">
  </iframe>
  </div>
</template>

<script>
import "./Contact.scss";
import { getAssetsInfo, updateContent } from "../../api/api.js";
import InlineEditor from "../InlineEditor.vue";

export default {
  name: "Contact",
  components: {
    InlineEditor,
  },
  data: () => ({
    form: null,
    savedNotification: false,
    failedNotification: false,
    assets: {}
  }),
  props: {
    data: Object,
  },
  // watch: {
  //   form: function() {
  //     console.log("change!!");
  //     clearTimeout(this.timer);
  //     this.timer = setTimeout(this.saveAction2, 3000);
  //   }
  // },
  methods: {
    log(msg) {
      console.log(msg);
    },
    save() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1500);
    },
    async saveAction() {
      // console.log('aaa')
      // console.log(content.target);
      // console.log(this.data);
      // console.log("contact", this.data.cmsid, {
      //   [content.target.dataset.key]: { [this.$lang]: content.target.innerHTML }
      // });
      // console.log(content.target.dataset.id);
      // console.log(content.target.innerHTML);
      // console.log(this.$lang)
      if (
        await updateContent("contact", this.data.cmsid, {
          mainContact: { [this.$lang]: this.form.mainContact },
          contact2: { [this.$lang]: this.form.contact2 },
          contacts: { [this.$lang]: this.form.contacts },
          contact2Title: { [this.$lang]: this.form.contact2Title },
          downloadTitle: { [this.$lang]: this.form.downloadTitle },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
  created() {
    if(this.$editor){
      this.form = { ...this.data };
    }

    // const id = this.data.download[0]
    this.data.download.forEach(async (item) => {
      const info = await getAssetsInfo(item)
      this.assets[item] = info.items[0]
      // this.assets.push({[item]: info.items[0]})
      // this.assets.splice(item,0,info.items[0])
      // this.assets
    });
    // console.log(this.assets)
  },
};
</script>
<style>
hr.contact {
  margin-top: 20px;
  margin-bottom: 0px;
}
span.contact2 {
  background-color: #a8b41a;
  color: #fff;
  font-weight: bold;
  padding: 3px 8px;
  display: inline-block;
  margin: 5px 0px;
  /* width: */
}
div.download{
  display: inline-block;
  color: #000;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 10px;
  min-width: 60px;
  max-width: 100px;
  margin: 2px;
  word-break: normal;
}
.map{
    width: 100%;
    height: 500px;
    border: 0;
}
</style>
